import { Box } from "@modernatx/ui-kit-react";
import React from "react";

import { useMapsLibrary } from "./GoogleAPIProvider";

interface MapProps extends google.maps.MapOptions {
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  hidden?: boolean;
}

interface GoogleMapsContextProps {
  map: google.maps.Map | null | undefined;
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
}

export const GoogleMapsContext = React.createContext<GoogleMapsContextProps>({
  map: null,
  defaultCenter: { lat: 0, lng: 0 },
  defaultZoom: 0
});

export const useMapInstance = () => {
  const context = React.useContext(GoogleMapsContext);
  if (!context) {
    throw new Error("useMapInstance must be used within a Map component");
  }
  return context.map;
};

export const Map: React.FC<MapProps> = (props: MapProps) => {
  const { defaultCenter, defaultZoom, disableDefaultUI, mapId, children, hidden } = props;
  const mapRef = React.useRef<HTMLDivElement | null>(null);
  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null);
  const googleMapsLibrary = useMapsLibrary();

  React.useEffect(() => {
    if (!mapInstance && mapRef.current && googleMapsLibrary) {
      const map = new googleMapsLibrary.Map(mapRef.current, {
        ...props,
        center: defaultCenter,
        zoom: defaultZoom
      });

      setMapInstance(map);
    }
  }, [defaultCenter, defaultZoom, googleMapsLibrary, mapId, disableDefaultUI, mapInstance, props]);

  const memoizedMapInstance = React.useMemo(() => {
    if (mapInstance) {
      return mapInstance;
    }
  }, [mapInstance]);

  const googleMapsContextValue = {
    map: memoizedMapInstance,
    defaultCenter,
    defaultZoom
  };

  return (
    <Box
      ref={mapRef}
      sx={{
        width: "100%",
        height: "100%",
        // @ts-ignore
        minHeight: ["300px", null, "600px"],
        display: hidden ? "none" : "block"
      }}
    >
      <GoogleMapsContext.Provider value={googleMapsContextValue}>
        {!!memoizedMapInstance && children}
      </GoogleMapsContext.Provider>
    </Box>
  );
};
