import { Box, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";

// FinderList renders the list of locations, taking into consideration the
// selected view and size breakpoints
export const FinderListViewWrapper = ({
  children,
  isMobileView
}: {
  children: React.ReactNode;
  isMobileView: boolean;
}) => {
  if (!isMobileView) {
    return (
      <Grid
        sx={{
          height: "100%",
          width: "100%",
          pointerEvents: "none",
          position: "absolute",
          py: 6
        }}
      >
        <GridColumn size={[4, 8, 6]} sx={{ justifyContent: "start", height: "100%" }}>
          <Box
            sx={{
              maxHeight: "100%",
              width: "100%",
              boxShadow: "boxShadow02",
              borderRadius: "large"
            }}
          >
            {children}
          </Box>
        </GridColumn>
      </Grid>
    );
  }
  return <>{children}</>;
};
